@charset "utf-8";
/* CSS Document */


/* ++++++++++++++++++++ */
/* +++ フリーページ共通 +++++++++ */
/* ++++++++++++++++++++ */
#sub_01{ margin:50px 0;}

#sub_01 h2{
	font-family:Arial, Helvetica, sans-serif;
	font-size:34px;
	text-align:center;
	font-weight:normal;
}

#sub_01 hr.line_1{
	border:none;
	background:#333;
	width:1px;
	height:50px;
	display:block;
	margin:20px auto 40px auto;
}



/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  ONCET .oncet_01  +++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */


.oncet_01 .inner_01{ width:1000px;}
.oncet_01 .inner_01 img{ display:block;}

.oncet_01 .bg_01{ background:url(https://cdn.shopify.com/s/files/1/0587/2019/0663/files/img-lp-2-01_oncet_bg.jpg) repeat-y; background-size:100% auto; -moz-background-size:100% auto;}

.slick-list{ z-index:1!important;}
.slick-slider{ width:80%!important; margin:0 auto;}
.slick-dots{ bottom:-60px!important;}

.oncet_01 .main_area_02{ background:url(https://cdn.shopify.com/s/files/1/0587/2019/0663/files/img-lp-2-01_oncet_bg.jpg) repeat-y; background-size:100% auto; -moz-background-size:100% auto; margin:0px auto; padding:0 0 20px 0;}
.oncet_01 .main_area_02 .item_03 .slick-track li{ margin:0 100px;}


.oncet_01 .button_01{ position:relative;}
.oncet_01 .button_01 a.link_01{
	position:absolute; top:0; left:52%; width:45%; height:100%; display:block;
	background:#fff;
	filter:alpha(style=0, opacity=0);
	-moz-opacity:0;
	opacity:0;
}

.oncet_01 .button_01 a:hover{
	filter:alpha(style=0, opacity=20);
	-moz-opacity:0.2;
	opacity:0.2;
}


@media screen and (max-width: 1024px) {
.oncet_01 .inner_01{ width:100%;}
.oncet_01 img{ width:100%;}
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
.slick-slide img{ width:98%;}
}
@media screen and (max-width: 330px) {
}




/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  コンセプト .concept_01  +++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

.concept_01 .inner_01{ width:1200px;}


@media screen and (max-width: 1024px) {
.concept_01 .inner_01{ width:100%;}
.concept_01 img{ width:100%;}
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}









/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  フォームエリア  +++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

/*　入力エリア　初期値　背景色*/
input:-webkit-autofill {    -webkit-box-shadow: 0 0 0 1000px white inset;}

input.form-control:focus{
	background-color:#f1f1f1!important;
	box-shadow: 0 0 0 1000px white inset;
	-webkit-box-shadow: 0 0 0 1000px white inset;
}

/*　ボタン　*/
.btn-primary{ background:#333!important; boder-color:#333!important;}

/*　注意　*/
.alert-info{ background:#f5f5f5!important; boder-color:#f5f5f5!important;}

/* テキストリンク　*/
.form-group a{ color:#900!important;}
.form-group a.btn-default{ color:#fff!important;}


/* チェックボックス　*/


@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}



/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  特定商取引法  +++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

.tokutei_01 table td{ padding:20px!important;}
.tokutei_01 table td:nth-child(odd){ width:15%;}
.tokutei_01 table td:nth-child(even){ width:80%;}

.tokutei_01 table td pre.info_ec{ line-height:21px;}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
.tokutei_01 table td:nth-child(odd){ width:35%;}
}
@media screen and (max-width: 330px) {
}



/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  プライバシーポリシー  +++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

.privacy_01 .table_01 th{
	width:18%;
	background:#fff!important;
	padding:10px!important;
	vertical-align:middle!important;
}
.privacy_01 .table_01 td{
	width:40%;
	padding:10px!important;
	vertical-align:middle!important;
}
.privacy_01 .table_01 td ul{ margin:0!important;}


@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 450px) {
}
@media screen and (max-width: 330px) {
}


/* +++++++++++++++++++++++++++++++++++++ */
/* ++++  company 会社概要  +++++++++++++++++ */
/* +++++++++++++++++++++++++++++++++++++ */

.company_01 a.link_contact_01{
	display:block;
	width:50%;
	margin:20px auto;
	padding:20px;
	text-align:center;
	font-size:16px;
	text-decoration:none;
	border:#333 2px solid;
	color:#333;
}
@media screen and (max-width: 450px) {
.company_01 a.link_contact_01{width:100%;}
}

